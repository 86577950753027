<template>
  <div>
    <TabTitle class="mb-4">
      <template>Create Project</template>
      <template #actions>
        <router-link class="btn btn-link" :to="{ name: 'asset-projects-list' }"><i class="fa fa-arrow-left mr-1"></i></router-link>
      </template>
    </TabTitle>
    <ProjectForm v-if="asset._id" :id="asset._id" v-model="form" mode="create" :error="validationErrors" />
    <div class="text-right pb-4">
      <button type="submit" class="btn btn-primary" data-cy="create-project-btn" :disabled="loadingAction.createProject" @click="onCreate">
        <span v-if="loadingAction.createProject"><i class="fa fa-spin fa-spinner mr-1"></i> Creating...</span>
        <span v-else><i class="fa fa-check-circle mr-1"></i> Create Project</span>
      </button>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import bluebird from 'bluebird';
import { mapGetters, mapActions } from 'vuex';

import ProjectForm from '@/components/forms/ProjectForm';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetProjectCreate',
  components: {
    ProjectForm,
    TabTitle
  },
  data() {
    return {
      templates: [],
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      loadingAction: 'asset/loadingAction',
      errorAction: 'asset/errorAction',
      validationErrors: 'asset/validationErrors'
    })
  },
  mounted() {
    this.form = {
      name: 'New Project',
      description: '',
      startDate: moment().add(1, 'month').startOf('month').toDate(),
      endDate: moment().add(1, 'month').endOf('month').toDate(),
      status: 'pending',
      accountIds: [],
      tasks: [],
      documents: [],
      uploads: []
    };
  },
  methods: {
    ...mapActions({
      createProject: 'asset/createProject',
      uploadProjectAttachment: 'asset/uploadProjectAttachment'
    }),
    async onCreate() {
      if (this.form.uploads.length > 0) {
        const uploads = await bluebird.map(
          this.form.uploads,
          async file => {
            return await this.uploadProjectAttachment({
              data: file.file,
              id: this.$route.params.id
            });
          },
          {
            concurrency: 5
          }
        );
        this.form.documents = uploads;
      }
      try {
        const editedForm = {
          ...this.form,
          assetId: this.$route.params.id,
          entityId: this.asset.entityId,
          companyId: this.asset.companyId,
          startDate: this.form.startDate,
          endDate: this.form.endDate
        };

        delete editedForm.uploads;

        const createProject = await this.createProject({ data: editedForm });

        if (Object.keys(this.validationErrors).length > 0) {
          window.scrollTo(0, 0);
          return this.$toasted.error('Could not create project.');
        }

        if (createProject._id) {
          this.$router.back();
          this.$toasted.success('Project created successfully.');
        }
      } catch (e) {
        this.$toasted.error('Could not create project.');
      }
    }
  }
};
</script>
